import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const MakeYourOwnCottonBallLauncher = () => (
  <Layout
    title="Make Your Own Cotton-Ball Launcher - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">
            Make Your Own Cotton-Ball Launcher
          </li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Make Your Own Cotton-Ball Launcher</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../inflation-station" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="d01ef698-054d-423c-83e8-57b4df3613bc"
                className="de-vid"
              ></Video>
              <Link to="../marshmallow-tower" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              What can a rubber band and a cotton ball teach you about potential
              and kinetic energy? Join Michael Lewandowski to make your own
              cotton-ball launcher and find out!
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              When you stretch a rubber band it stores elastic potential
              energy—the energy stored inside a material when it is stretched,
              squished, bent or twisted. This is different from gravitational
              potential energy, which is stored in an object that is lifted off
              the ground. Both types of potential energy can be converted to
              kinetic—the energy of motion. All moving objects have kinetic
              energy, but motionless ones have none. When energy is converted
              between forms the total amount of energy remains the same. In
              other words, it is conserved. (Some energy, however, may also be
              converted to heat due to friction—but that is still a form of
              energy.)
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Physics</li>
              <li>Potential energy</li>
              <li>Kinetic energy</li>
              <li>Conservation of energy</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Short pencil or Popsicle stick</li>
              <li>Two thin rubber bands</li>
              <li>
                Two empty toilet paper tube or one empty paper towel tube cut in
                half
              </li>
              <li>Packing tape or other strong tape</li>
              <li>Scissors</li>
              <li>Cotton balls</li>
              <li>Yardstick</li>
              <li>Ruler</li>
              <li>Three pieces of paper</li>
              <li>Partner</li>
              <li>Single-hole puncher (optional)</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>Gather your materials in a clear, open area.</li>
              <li>
                Use your yardstick to measure out a distance of three yards
                using a piece of paper to mark each yard. Place your yardstick
                in the first yard. This will be your launching station. How far
                do you think you will be able to launch your cotton balls?
              </li>
              <li>
                Use your scissors to carefully cut one of the toilet paper tubes
                in half lengthwise.
              </li>
              <li>
                Squeeze the roll so it becomes narrower (about half the original
                diameter) then tape it to hold it in place.
              </li>
              <li>
                Use your scissors or your hole puncher to make two holes in the
                skinny tube. (If you’re using a popsicle stick, use scissors to
                make narrower holes the same shape as the popsicle stick.) Make
                the holes opposite each other, half an inch away from the end,
                so you can poke your pencil or popsicle stick all the way
                through the tube.
              </li>
              <li>
                Carefully push your pencil or popsicle stick through the holes.
              </li>
              <li>
                On your second toilet paper tube cut two slits into one end of
                the tube about one quarter inch long and one-half inch apart.
              </li>
              <li>
                Cut two more slits on the same end of the tube directly across
                from the first two.
              </li>
              <li>
                Carefully loop one rubber band through the slits on one side so
                that it hangs from the cardboard piece in the middle. Put a
                piece of tape over the slits to reinforce the cardboard tab.
              </li>
              <li>
                Loop the other rubber band through the slits on the other side
                of the tube. When you are finished the tube should have a rubber
                band hanging from each side.
              </li>
              <li>
                Holding the rubber-band tube so its rubber bands are at the top,
                slide the narrower tube into the wider one with the pencil end
                at the bottom.
              </li>
              <li>Carefully loop each rubber band end around the pencil.</li>
              <li>
                Hold your launcher so that the pencil is at the bottom. Place a
                cotton ball on the top so that it rests inside on the narrower
                tube.
              </li>
              <li>
                Go to your launching station and stand next to the zero-end of
                the yardstick. Now that you have built your launcher, how far do
                you think your cotton ball will fly?
              </li>
              <li>
                Hold your launcher slightly horizontally (without dropping the
                cotton ball). Have your partner hold the ruler next to your
                launcher.
              </li>
              <li>
                Pull back on the pencil so the inner tube extends two inches out
                the back of the launcher. Carefully aim your cotton ball (away
                from people).
              </li>
              <li>Release the pencil and watch your cotton ball fly!</li>
              <li>
                Use your yardstick to measure the distance the cotton ball
                traveled.
              </li>
              <li>
                Repeat the previous steps, each time pulling the launcher back
                one inch farther. When did the cotton ball the travel the
                shortest distance? When did it travel the farthest?
              </li>
              <li>
                Extra: Try testing different rubber-band thicknesses and see
                which launch the farthest!
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              In this activity you used two types of energy to load and launch
              your cotton ball. As you drew back on the pencil with the cotton
              ball loaded you were adding potential energy to the system. The
              farther you pulled back on the pencil, the more potential energy
              was being stored. When you released the pencil, the energy became
              kinetic and the cotton ball should have gone flying through the
              air!
            </p>
            <p>
              The farther you pulled back on your launcher the more potential
              energy you added to the system—and the more you stored the more
              kinetic energy should have been released when you shot the cotton
              ball. As a result, the farther you pulled back on the launcher,
              the farther the cotton ball should have traveled.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS3.A: Definitions of Energy
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-1.</em> The faster a given object is moving,
                        the more energy it possesses.
                      </li>
                      <li>
                        <em>4-PS3-2,3.</em> Energy can be moved from place to
                        place by moving objects or through sound, light, or
                        electric currents.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-1.</em> Motion energy is properly called
                        kinetic energy; it is proportional to the mass of the
                        moving object and grows with the square of its speed.
                      </li>
                      <li>
                        <em>MS-PS3-2.</em> A system of objects may also contain
                        stored (potential) energy, depending on their relative
                        positions.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-1.</em> Energy is a quantitative property of
                        a system that depends on the motion and interactions of
                        matter and radiation within that system.
                      </li>
                      <li>
                        <em>HS-PS3-2.</em> There is a single quantity called
                        energy due to the fact that a system’s total energy is
                        conserved, even as, within the system, energy is
                        continually transferred from one object to another and
                        between its various possible forms.
                      </li>
                      <li>
                        <em>HS-PS3-3.</em> At the macroscopic scale, energy
                        manifests itself in multiple ways, such as in motion,
                        sound, lights, and thermal energy.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS3.B: Conservation of Energy and Energy Transfer
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-2.</em> Energy is present whenever there are
                        moving object.
                      </li>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, energy can be
                        transferred from one object to another thereby changing
                        their motion.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-5.</em> When the motion energy of an object
                        changes, there is inevitably some other change in energy
                        at the same time.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="MakeYourOwnCotton-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Michael Lewandowski</h3>
                <h4>Still need...</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="InflationStation-Thumbnail.png" />
              <div className="card__content">
                <h3>Inflation Station</h3>
                <p>
                  Follow along with 3M’s Chief Science Advocate, Jayshree Seth,
                  as she teaches students how chemistry can help put some air
                  where it’s most needed!
                </p>
              </div>
              <Link
                to="../inflation-station"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MarshmellowTower-Thumbnail.png" />
              <div className="card__content">
                <h3>Marshmallow Tower</h3>
                <h4>Have you ever wondered how skyscrapers can be so tall?</h4>
                <p>
                  Or how people build bridges to span long distances? Explore
                  engineering techniques to build sturdy structures using only
                  marshmallows and uncooked spaghetti.
                </p>
              </div>
              <Link
                to="../marshmallow-tower"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="C02Balloon-Thumbnail.png" />
              <div className="card__content">
                <h3>
                  C0<sub>2</sub> Balloon
                </h3>
                <h4>
                  Baking soda and acid reactions in baking make things puff and
                  rise.
                </h4>
                <p>
                  But how could you use this same chemical reaction to blow up a
                  balloon?
                </p>
              </div>
              <Link to="../c2-balloon" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default MakeYourOwnCottonBallLauncher;
